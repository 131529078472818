import { Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { useCallback } from 'react';

import { useStyles } from './Comments.styles';
import { i18n } from './Comments.i18n';

export const ReplyButton = ({
    id,
    setCommentedId,
    setOpen,
}: {
    id: string;
    setCommentedId: Function;
    setOpen: Function;
}) => {
    const { classes } = useStyles();
    const intl = useIntl();

    const onClick = useCallback(() => {
        setCommentedId(id);
        setOpen(true);
    }, [id, setCommentedId, setOpen]);

    return (
        <Typography
            className={classes.linkText}
            onClick={onClick}
            automation-id={`reply-button-${id}`}
        >
            {intl.formatMessage(i18n.replyButton)}
        </Typography>
    );
};
